import React, { Suspense } from 'react';
import App from 'next/app';
import { withRouter } from 'next/router';
import { ErrorBoundary } from '@sentry/react';

import ErrorMessage from '../src/components/Error';
import GlobalStyles from '../src/styles/global';
import PageTransition from '../src/components/PageTransition';

import '../src/utils/sentry';
import GoolgeAnalytics from '../src/components/GoogleAnalytics';

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <Suspense fallback={null}>
        <GoolgeAnalytics />
        <ErrorBoundary
          fallback={() => {
            return <ErrorMessage />;
          }}
        >
          <GlobalStyles />
          <PageTransition {...pageProps} />
          <Component {...pageProps} />
        </ErrorBoundary>
      </Suspense>
    );
  }
}

export default withRouter(MyApp);
