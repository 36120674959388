import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const DSN =
  'https://84580f060f164cd5a39ea73f3066e0fe@o423780.ingest.sentry.io/6172658';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export const captureException = (error: any, extra?: Record<string, any>) => {
  // User error, no need to report
  if (error.noSentry) return false;

  Sentry.configureScope((scope) => {
    if (extra) {
      Object.keys(extra).forEach((key) => {
        scope.setExtra(key, extra[key]);
      });
    }

    if (error.extraData) {
      Object.keys(error.extraData).forEach((key) => {
        scope.setExtra(key, error.extraData[key]);
      });
    }
  });

  return Sentry.captureException(error);
};
