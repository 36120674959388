export function clamp(val: number, min: number, max: number) {
  return Math.max(min, Math.min(max, val));
}

export function lerp(start: number, end: number, t: number) {
  return start * (1 - t) + end * t;
}

export function mapLinear(
  x: number,
  a1: number,
  a2: number,
  b1: number,
  b2: number
) {
  return b1 + ((x - a1) * (b2 - b1)) / (a2 - a1);
}

export const randomInt = (low: number, high: number) =>
  low + Math.floor(Math.random() * (high - low + 1));

export const randomFloat = (low: number, high: number) =>
  low + Math.random() * (high - low);

export const pythagorean = (sideA, sideB) => {
  return Math.sqrt(sideA ** 2 + sideB ** 2);
};
