import colorString from 'color-string';
import { lerp } from '../utils/math';

// convert color in RGB color space [255, 255, 255, 1] to HSL [1, 1, 1, 1]
const rgbToHsl = (color: number[]): number[] => {
  let [r, g, b] = color;
  r /= 255;
  g /= 255;
  b /= 255;
  const a = color[3];

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = h;
  const l = h;

  if (max === min) {
    h = 0;
    s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }

    h /= 6;
  }

  return [h, s, l, a];
};

// convert color in HSL color space [1, 1, 1, 1] to RGB [255, 255, 255, 1]
// ts-unused-exports:disable-next-line
export const hslToRgb = (color: number[]): number[] => {
  const [h, s, l, a] = color;
  let r: number;
  let g: number;
  let b: number;

  /* eslint-disable */
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  /* eslint-enable */

  return [r * 255, g * 255, b * 255, a];
};

export class Color {
  value: number[];

  constructor(value: string | number[], opacity?: 1) {
    if (Array.isArray(value)) {
      this.value = value;

      if (opacity) {
        this.value[3] = opacity;
      }
    } else {
      this.value = colorString.get.rgb(value);

      if (opacity) {
        this.value[3] = opacity;
      }
    }
  }

  opacity(opacity: number, chain?: boolean) {
    const mixed = colorString.to.rgb([...this.value.slice(0, 3), opacity]);
    if (chain) {
      return new Color(mixed);
    }
    return mixed;
  }

  mix(color2: Color | string, a: number, chain?: boolean): string | Color {
    const c1 = this.value;
    const c2 = typeof color2 === 'string' ? new Color(color2) : color2.value;
    const mix = [
      lerp(c1[0], c2[0], a),
      lerp(c1[1], c2[1], a),
      lerp(c1[2], c2[2], a),
      lerp(c1[3], c2[3], a),
    ];

    // return as RGB color again
    if (chain) {
      return new Color(mix);
    }
    return colorString.to.rgb(mix);
  }

  equals(color2: Color | string): boolean {
    if (!color2) {
      return false;
    }

    const c1 = this.value;
    const c2 = typeof color2 === 'string' ? new Color(color2) : color2.value;
    return c1[0] === c2[0] && c1[1] === c2[1] && c1[2] === c2[2];
  }

  invert(bw?: boolean, light = '#FFFFFF', dark = '#000000') {
    if (bw) {
      // http://stackoverflow.com/a/3943023/112731
      return this.value[0] * 0.299 +
        this.value[1] * 0.587 +
        this.value[2] * 0.114 >
        186
        ? dark
        : light;
    }

    const c = this.value.map((v, i) => {
      if (i !== 3) return 255 - v;
      return v;
    });

    return colorString.to.hex(c);
  }

  get hsl() {
    return rgbToHsl(this.value);
  }

  get hex() {
    return colorString.to.hex(this.value);
  }

  get rgb() {
    return colorString.to.rgb(this.value);
  }

  get luma() {
    const [r, g, b] = this.value;
    return Math.sqrt(0.241 * r + 0.691 * g + 0.068 * b);
  }

  get css() {
    if (this.value[3] === undefined || this.value[3] === 1) {
      return colorString.to.hex(this.value);
    }

    return this.rgb;
  }
}

const brand = new Color('#2709DC');

const colors = {
  white: new Color('#fff'),
  black: new Color('#151414'),
  text: {
    regular: new Color('#242323'),
    light: new Color('#595959'),
  },
  brand: {
    regular: brand,
    light: new Color('#EAE7F1'),
    mid: new Color('#E9E4F4'),
  },
  error: {
    regular: new Color('#DC0916'),
    light: new Color('#F4DBDC'),
    dark: new Color('#C96262'),
  },
  success: {
    regular: new Color('#107745'),
    bright: new Color('#09DC77'),
    light: new Color('#B9F9DA'),
    dark: new Color('#107745'),
  },
  warn: {
    regular: new Color('#DCAE09'),
    light: new Color('#F4F1DB'),
    dark: new Color('#8B600C'),
  },
  shades: {
    s100: new Color('#F2F2F2'),
    s200: new Color('#DFDFE0'),
    s250: new Color('#BBBBBB'),
    s300: new Color('#6E6E6E'),
    s400: new Color('#424242'),
    s500: new Color('#151414'),
  },
  section: {
    background: brand.opacity(0.02),
  },
  blocks: {
    text: new Color('#2F2F2F'),
    outline: new Color('#ABA8A8'),
    background: new Color('#F9F8F5'),
    body: new Color('#F9F9F9'),
  },
  rainbow: {
    white: {
      regular: new Color('#000'),
      light: new Color('#fff'),
      dark: new Color('#000'),
    },
    black: {
      regular: new Color('#fff'),
      light: new Color('#000'),
      dark: new Color('#fff'),
    },
    brown: {
      regular: new Color('#6F411F'),
      light: new Color('#A59080'),
      dark: new Color('#351C0A'),
    },
    orange: {
      regular: new Color('#cf760E'),
      light: new Color('#edaf67'),
      dark: new Color('#6d3b00'),
    },
    yellow: {
      regular: new Color('#ad8b13'),
      light: new Color('#ecde72'),
      dark: new Color('#8B600C'),
    },
    green: {
      regular: new Color('#12CA46'),
      light: new Color('#77f59a'),
      dark: new Color('#096222'),
    },
    cyan: {
      regular: new Color('#1DC6DD'),
      light: new Color('#A5F2FD'),
      dark: new Color('#035560'),
    },
    blue: {
      regular: new Color('#2b3eea'),
      light: new Color('#86aaf1'),
      dark: new Color('#011957'),
    },
    purple: {
      regular: new Color('#962bea'),
      light: new Color('#d09ef8'),
      dark: new Color('#46027b'),
    },
    pink: {
      regular: new Color('#d71d76'),
      light: new Color('#f6a4cc'),
      dark: new Color('#80003d'),
    },
    red: {
      regular: new Color('#ea2b2b'),
      light: new Color('#f5bfbf'),
      dark: new Color('#710000'),
    },
  },
};

export default colors;
