import { css } from 'styled-components';
import localFont from 'next/font/local';

export const base = 16;

export const fontClassNames = localFont({
  fallback: [
    '-apple-system,system-ui',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
  ],
  preload: true,
  display: 'swap',
  src: [
    // NOT USED
    // {
    //   path: '../fonts/AktivGrotesk_W_Lt.woff2',
    //   weight: '300',
    //   style: 'normal',
    // },
    {
      path: '../fonts/AktivGrotesk_W_Rg.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../fonts/AktivGrotesk_W_Md.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../fonts/AktivGrotesk_W_SBd.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../fonts/AktivGrotesk_W_Bd.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
});

export const FONT_STRING = fontClassNames.style.fontFamily;

const fonts = {
  aktiv: {
    // light: css`
    //   font-family: ${fontClassNames.style.fontFamily};
    //   font-weight: 300;
    // `,
    regular: css`
      font-family: ${fontClassNames.style.fontFamily};
      font-weight: 400;
    `,
    medium: css`
      font-family: ${fontClassNames.style.fontFamily};
      font-weight: 500;
    `,
    semiBold: css`
      font-family: ${fontClassNames.style.fontFamily};
      font-weight: 600;
    `,
    bold: css`
      font-family: ${fontClassNames.style.fontFamily};
      font-weight: 700;
    `,
  },
};

export default fonts;
