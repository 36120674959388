import { keyframes, css } from 'styled-components';

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const fadeElement = (duration = 300, delay = 300) => css`
  animation: ${fadeIn} ${duration}ms ease-out;
  animation-fill-mode: both;
  animation-delay: ${delay}ms;
`;
export const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

export const fadeTranslateIn = (h: number, v: number) => keyframes`
  0% {
    opacity: 0;
    transform: translate(${h}px, ${v}px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const bounceIn = keyframes`
0% {
      opacity: 0;
      transform: scale(.3);
  }

  50% {
      opacity: 1;
      transform: scale(1.05);
  }

  70% {
      transform: scale(.9);
  }

  100% {
      transform: scale(1);
  }
`;

export const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SvgLineAnim = (
  end: number,
  offset = 0,
  invert = false
) => keyframes`
  0%
  {
    stroke-dashoffset: ${invert ? end - offset : 0 - offset}px;
  }
  100%
  {
    stroke-dashoffset: ${invert ? 0 - offset : end - offset}px;
  }
`;

export const staggerChildrenFadeTranslateIn = (
  active: boolean,
  offset = -10,
  duration = 1,
  staggering = 0.2,
  startingDelay = 0
) => {
  if (!active) {
    return css`
      & > * {
        opacity: 0;
      }
    `;
  }
  return css`
    & > * {
      ${() => {
        return Array(9)
          .fill(0)
          .map((_, child) => {
            return css`
              &:nth-child(${child + 1}) {
                animation: ${fadeTranslateIn(0, offset)} ${duration}s ease-out;
                animation-fill-mode: both;
                animation-delay: ${startingDelay + child * staggering}s;
              }
            `;
          });
      }}
    }
  `;
};
