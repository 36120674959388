import React, { useEffect } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';

export const GTM_ID = 'GTM-587FF3W7';

function GoolgeAnalytics() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if ('dataLayer' in window) {
        // @ts-ignore
        (window.dataLayer as any).push({ event: 'pageview', page: url });
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <Script id="google-analytics">
      {`
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
         'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer', '${GTM_ID}');
        `}
    </Script>
  );
}

export default GoolgeAnalytics;
