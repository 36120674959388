import { css } from 'styled-components';

export const breakpointSizes = {
  extraSmall: 640,
  small: 768,
  medium: 960,
  large: 1400,
  extraLarge: 1600,
  ipad: 1024,
  ipadPro: 1366,
};

const breakpoints: Record<string, any> = { min: {}, max: {} };

const query =
  (size, direction = 'max') =>
  (content, ...args) =>
    css`
      @media screen and (${direction}-width: ${size}px) {
        ${css(content, ...args)}
      }
    `;

// ts-unused-exports:disable-next-line
export const ipadPortrait = (property, ...values) => css`
  @media all and (device-width: ${breakpointSizes.small}px) and (device-height: ${breakpointSizes.ipad}px) and (orientation: portrait),
    all and (device-width: ${breakpointSizes.ipad}px) and (device-height: ${breakpointSizes.ipadPro}px) and (orientation: portrait) {
    ${css(property, ...values)}
  }
`;

Object.keys(breakpointSizes).forEach((key: string) => {
  // default (max)
  breakpoints[key] = query(breakpointSizes[key]);
  breakpoints.min[key] = query(breakpointSizes[key], 'min');
  breakpoints.max[key] = query(breakpointSizes[key], 'max');
});

breakpoints.custom = query;

// USAGE EXAMPLE
// breakpoints.small`-css here-`
// breakpoints.min.small`-css here-`
// breakpoints.max.large`-css here-`
// breakpoints.custom(1000, 'max')`-css here-`
export default breakpoints;
