import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { SvgLineAnim } from './index';

const OFFSET = 600;
const DURATION = 20;

const FLOW_LOGO_PATH =
  'M51.254 53.0354C57.002 61.4701 62.8633 70.0356 69.2474 77.8164C75.2144 85.1047 89.3809 100.764 91.7476 82.4043C92.1082 79.2294 92.0489 75.875 91.7476 72.4488C91.1708 66.0042 89.8316 59.421 88.0624 53.038C83.2853 35.948 72.6854 11.2901 55.9564 3.19903C45.7274 -1.68895 35.7585 2.06808 27.9915 9.58982C15.1769 22.0662 7.09569 42.6696 3.22762 59.9006C1.65413 67.3223 0.477224 75.0236 1.24981 82.4017C2.6044 93.6446 8.89065 92.6906 16.0551 86.0895C18.7411 83.6455 21.2674 80.8169 23.75 77.8138C28.3907 72.177 32.7763 66.0375 37.041 59.9006C46.3197 46.5497 55.2225 32.4807 65.0059 19.5427C68.9229 14.5778 83.7205 -5.60497 89.7724 3.19647C91.7244 6.35083 91.9433 10.3028 92 14.1752C91.8712 24.8692 89.2934 35.7351 85.9043 45.9958C81.0164 60.252 72.9764 77.0855 60.561 86.0921C46.7653 95.9321 33.5438 89.8081 23.7526 77.8164C12.3209 63.7423 4.91958 43.321 1.99664 25.5668C1.1674 20.2069 0.672945 14.765 1.24981 9.58982C2.10995 2.1245 5.61233 -1.71459 12.6866 3.19903C15.1975 4.93778 17.4844 7.15096 19.7558 9.58982C22.5911 12.6519 25.3183 16.0345 27.9941 19.5453C36.1165 30.2701 43.6466 41.8566 51.254 53.0354Z';

const FOUR_PATH =
  'M13.6197 0H10.2595L0 14.1185V16.8785H10.1522V22.2923H13.6197V16.8785H16.265V14.0477H13.6197V0ZM10.1522 4.49385V14.0477H3.3245L10.1522 4.49385Z';

type PathProps = {
  $length: number;
};

const Path = styled.path<PathProps>`
  stroke-dasharray: ${(props) => `${props.$length - OFFSET} ${OFFSET}`};
  stroke-dashoffset: ${(props) => props.$length - OFFSET};
  animation: ${(props) => SvgLineAnim(props.$length, OFFSET, true)} ${DURATION}s
    linear infinite;
`;
const SVG = styled.svg`
  max-width: 100px;
  max-height: 100px;
  overflow: visible;
`;

interface LogoNotFoundProps extends React.HTMLAttributes<SVGSVGElement> {
  width?: number;
  height?: number;
}

const LogoNotFound = ({ width, height, ...props }: LogoNotFoundProps) => {
  const pathRef = useRef(null);
  const [length, setLength] = useState(null);

  // run once
  useEffect(() => {
    const pathElement = pathRef.current;

    if (pathElement) {
      // set path length
      setLength(pathElement.getTotalLength());
    }
  }, []);

  return (
    <SVG viewBox="0 0 93 92" width={width} height={height} {...props}>
      <defs>
        <Path
          ref={pathRef}
          $length={length}
          d={FLOW_LOGO_PATH}
          strokeLinecap="round"
          stroke="currentColor"
          fill="none"
          id="animatedPath"
        />
        <path d={FOUR_PATH} fill="currentColor" stroke="none" id="four" />
        <line
          x1="46"
          x2="46"
          y1="58"
          y2="70"
          strokeLinecap="round"
          transform="rotate(-35,46,46)"
          id="magnifyingGlassLine"
        />
        <clipPath id="magnifyingGlassClip">
          <circle cx="46" cy="46" r="8" />
        </clipPath>
      </defs>
      <path
        d={FLOW_LOGO_PATH}
        strokeWidth={1}
        strokeDasharray="1 4"
        fill="none"
        strokeLinecap="round"
        stroke="currentColor"
      />
      <use href="#animatedPath" strokeWidth={2} />
      <use href="#magnifyingGlassLine" strokeWidth={6} stroke="white" />
      <use href="#four" transform="translate(16,35)" />
      <circle
        cx="46"
        cy="46"
        r="12"
        strokeWidth={2}
        stroke="currentColor"
        fill="white"
        strokeLinecap="round"
      />
      <use href="#four" transform="translate(60,35)" />
      <use href="#magnifyingGlassLine" strokeWidth={2} stroke="currentColor" />
      <use
        clipPath="url(#magnifyingGlassClip)"
        href="#animatedPath"
        strokeWidth={4}
      />
    </SVG>
  );
};

export default LogoNotFound;
