import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';

import ErrorIcon from '../styles/animations/LogoError';
import NotFoundIcon from '../styles/animations/LogoNotFound';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ${TYPO.p1}
  color: ${COLORS.text.regular.css};
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const TextContainer = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  h1 {
    ${TYPO.h1}
    margin: 0px 0 8px;
  }

  a {
    color: ${COLORS.brand.regular.css};
    text-decoration: underline;
    cursor: pointer;
  }

  & p {
    ${TYPO.p2}
    margin: 0 0 2px;
    padding: 0;
  }
`;

export enum ErrorType {
  NOT_FOUND = 'not found',
  GENERIC = 'generic',
}

type ErrorProps = {
  type?: ErrorType;
};

export default function Error({ type }: ErrorProps) {
  if (type === ErrorType.NOT_FOUND) {
    return (
      <Container>
        <Inner>
          <NotFoundIcon width={80} height={80} />
          <TextContainer>
            <h1>Page not found</h1>
            <p>We can&apos;t find the page you were looking for.</p>
            <p>
              Click{' '}
              <Link href="/" as="/">
                here
              </Link>{' '}
              to go back to the homepage
            </p>
          </TextContainer>
        </Inner>
      </Container>
    );
  }

  return (
    <Container>
      <Inner>
        <ErrorIcon />
        <TextContainer>
          <h1>Something went wrong</h1>
          <p>Our team has been notified.</p>
          <p>
            Click{' '}
            <Link href="/" as="/">
              here
            </Link>{' '}
            to go back to the homepage
          </p>
        </TextContainer>
      </Inner>
    </Container>
  );
}
