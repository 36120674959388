import React from 'react';
import styled from 'styled-components';

import COLORS from '../colors';
import { SvgLineAnim } from './index';

import { ReactComponent as FlowErrorIcon } from '../../assets/flow-error-icon.svg';

const Container = styled.div`
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  position: relative;
`;

const ErrorIcon = styled(FlowErrorIcon)`
  width: 100%;
  height: 100%;
  & path {
    stroke-dasharray: 700px 6px 1px 6px;
    animation: ${SvgLineAnim(1400)} 30s linear infinite;
    stroke-linecap: round;
    stroke-width: 2px;
  }
`;

const Sparks = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 35px;
  animation: ${SvgLineAnim(14)} 2s linear infinite;
  z-index: 1;
  & line {
    stroke: ${COLORS.shades.s500.css};
    stroke-width: 2px;
    stroke-dasharray: 7px;
  }
`;

const ErrorAnimation = (props: React.HTMLAttributes<HTMLElement>) => {
  return (
    <Container {...props}>
      <ErrorIcon />
      <Sparks xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 50">
        <line x1="18" y1="0" x2="18" y2="7" />
        <line x1="0" y1="18" x2="7" y2="18" />
        <line x1="24" y1="50" x2="24" y2="43" />
        <line x1="42" y1="32" x2="35" y2="32" />
      </Sparks>
    </Container>
  );
};

export default ErrorAnimation;
