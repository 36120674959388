import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import anime from 'animejs';

import COLORS from '../styles/colors';

const DURATION_IN = 600;
const DURATION_OUT = 800;
const DELAY = 50;
const PATHS = [COLORS.shades.s100.css, COLORS.white.css, COLORS.black.css];

const INTRO = [
  'M 0 100 V 100 Q 50 100 100 100 V 100 z',
  'M 0 100 V 50 Q 50 20 100 50 V 100 z',
  'M 0 100 V 0 Q 50 0 100 0 V 100 z',
];

const OUTRO = [
  'M 0 0 V 100 Q 50 100 100 100 V 0 z',
  'M 0 0 V 50 Q 50 80 100 50 V 0 z',
  'M 0 0 V 0 Q 50 0 100 0 V 0 z',
];

const SVG = styled.svg`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
  overflow: hidden;
`;

const PageTransition = () => {
  const router = useRouter();
  const animation = useRef<anime.AnimeInstance | null>(null);
  const elements = useRef<SVGPathElement[]>([]);

  useEffect(() => {
    const endAnimation = () => {
      anime.set(elements.current, { d: OUTRO[0] });
      anime({
        targets: elements.current,
        keyframes: [
          { d: OUTRO[1], easing: 'easeInCubic' },
          { d: OUTRO[2], easing: 'easeOutCubic' },
        ],
        duration: DURATION_OUT,
        delay: anime.stagger(DELAY, { from: 'last' }),
        complete: () => {
          animation.current = null;
        },
      });
    };

    const end = () => {
      if (animation.current) {
        if (animation.current?.completed) {
          endAnimation();
        } else {
          animation.current.finished.then(endAnimation);
        }
      }
    };

    const startAnimation = () => {
      anime.set(elements.current, { d: INTRO[0] });
      animation.current = anime({
        targets: elements.current,
        keyframes: [
          { d: INTRO[1], easing: 'easeInCubic' },
          { d: INTRO[2], easing: 'easeOutCubic' },
        ],
        duration: DURATION_IN,
        delay: anime.stagger(DELAY, { from: 'first' }),
      });
    };

    const start = (slug) => {
      if (router.asPath !== slug) {
        router.events.emit('routeChangeError');
        startAnimation();
      }
    };

    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    // router.events.on('routeChangeError', end);

    const setDebug = (evt) => {
      // when pressing '[' switches to showing keys in front-end
      if (evt.keyCode === 219) {
        startAnimation();
      }
      if (evt.keyCode === 221) {
        endAnimation();
      }
    };

    if (process.env.BUILD_ENV !== 'production') {
      window.addEventListener('keydown', setDebug);
    }

    return function unbind() {
      router.events.off('beforeHistoryChange', start);
      router.events.off('routeChangeComplete', end);
      // router.events.off('routeChangeError', end);
      window.removeEventListener('keydown', setDebug);
    };
  }, [router]);

  return (
    <SVG
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      {PATHS.map((color, i) => {
        return (
          <path
            key={color}
            ref={(el) => {
              elements.current[i] = el;
            }}
            vectorEffect="non-scaling-stroke"
            fill={color}
            d={INTRO[0]}
          />
        );
      })}
    </SVG>
  );
};

export default PageTransition;
